ul {
  list-style-type: none;
  text-align: left;
}

.added {
  background-color: #91e68e;
  color: #254f24;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.author-profile {
  border-right: 1px dotted gray;
}

.character-name {
  font-weight: bold;
  text-align: left;
}

.cut {
  background-color: #eb847c;
  color: #a61105;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.draggable-item-heading {
  font-weight: 600;
}

.draggable-reason-for-recommendation {
  font-size: 0.75em;
}

.draggable-further-info {
  font-size: 0.75em;
}

.doubling-problem {
  background-color: #f08e16;
}

.fas {
  padding: 4px;
}

.form-radio {
  text-align: left;
}

.instructions {
  font-style: italic;
  font-size: 0.8em;
}

.line {
  text-align: left;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nonspeaking-onstage {
  background-color: red;
}
.rie-select {
  display: inline-block;
  width: 30px;
}

.script-cut-interface-row:nth-child(odd) {
  background-color: #dcdcdc;
}

.script-cut-interface-row:nth-child(even) {
  background-color: #ffff;
}

.no-character {
  font-size: 0.75em;
  font-style: italic;
  font-weight: normal;
}

.stage-direction {
  font-style: italic;
  text-align: left;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

footer {
  padding: 80px;
  height: 100px;
}
